import { BaseDto } from "@/shared/dtos/base-dto";

export class CalendarDay extends BaseDto {
  public Day!: number;
  public Mouth!: number;
  public Year!: number;
  public CssClass!: string;
  public Color!: string;
  public activo!: boolean;
  public CompleteDate!: Date;
  public FormatDateStr!: string;
  public anomesdia!: string;

  public seleccionado!: boolean;
  public liPadding!: number;
}
